<template>
  <div id="data-list-thumb-view" class="data-list-container">
    <vs-table
      ref="table"
      multiple
      pagination
      search
      v-model="selected"
      :max-items="itemsPerPage"
      :data="products"
    >
      <template slot="thead">
        <vs-th>الصورة</vs-th>
        <vs-th sort-key="name">الإسم</vs-th>
        <vs-th sort-key="main_category">القسم الرئيسي</vs-th>
        <vs-th sort-key="sub_category">القسم الفرعي</vs-th>
        <vs-th sort-key="sku">SKU</vs-th>
        <vs-th sort-key="price">السعر</vs-th>
        <vs-th>العمليات</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td
              class="table-image-container flex justify-center items-center"
            >
              <!-- <img :src="tr.media[0].full_path" class="table-image" /> -->
              <img :src="tr.first_media" class="table-image" />
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.title }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.category.name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.sub_category.name }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.sku }}</p>
            </vs-td>

            <vs-td>
              <p class="product-price">{{ tr.price }}LYD</p>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon
                icon="RepeatIcon"
                @click="confirm(tr.id)"
                svgClasses="w-5 h-5 hover:text-warning stroke-current"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  data() {
    return {
      search: "",
      selected: [],
      products: [],
      isMounted: false,
      itemsPerPage:5,
      totalItems:'',
      lastPage:'',
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.products.length;
    },
  },

  methods: {
    ...mapActions('products', [
      'fetchArchivedProducts',
      'retrieveProduct'
    ]),
    getData() {
      this.fetchArchivedProducts()
        .then((response) => {
          this.products = response.data.data.data
        })
    },
    confirm(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: 'تأكيد',
        text: ` هل انت متأكد من استعادة هذا المنتج؟`,
        accept: this.restoreProduct(id),
        acceptText: 'نعم',
        cancelText: 'الغاء',
      });
    },
    restoreProduct(id) {
      this.retrieveProduct(id)
        .then(() => {
          this.getData();
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تمت إستعادة المنتج بنجاح",
            color: "success",
          });
        })
        .catch((error) => {
          this.errorDialog(error);
        });
    },
  },
  mounted() {

  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.table-image-container {
            height: 110px;

            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .table-image {
              height: 68px;
            }
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
